import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useScreenRecognition } from '@reckitt/phx-husky.hooks.use-screen-recognition';

import LdsModal from 'common/LdsModal';

import promoPageContent from '../../../content/promoPageContent.json';
import { LdsType } from './model';

import './PromoPage.scss';

const dropSupport = '../../../static/images/dropSupport.png';
const productsFinish = '../../../static/images/productsFinish.png';
const finishBon = '../../../static/images/finishBon.png';
const finish = '../../../static/images/finish.png';

const PromoPage = () => {
  const [isLdsModalShown, setLdsModalShown] = useState(false);
  const { isMobile } = useScreenRecognition();

  const data: LdsType = useStaticQuery(graphql`
    {
      lds {
        internal {
          content
        }
      }
    }
  `);

  const {
    contest: { contestTitle, contestNumber, contestSign, contestBon, contestPrice },
    ldsTerms: { ldsButtonName },
    steps: {
      firstStep,
      firstStepSpan,
      firstStepReceipt,
      secondStep,
      secondStepSpan,
      thirdStep,
      thirdStepSpan,
    },
    learnMore: { competitionTime, details, detailsMobile },
    seo: { title, description, keywords, lang },
    imagesAlts: { supportImgAlt, finishImgAlt, finishBonAlt, finishProductsImgAlt },
  } = promoPageContent;

  const toggleLdsModal = () => {
    setLdsModalShown(!isLdsModalShown);
  };

  return (
    <>
      <Helmet
        title={title}
        titleTemplate={title}
        htmlAttributes={{
          lang,
        }}
      >
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <div className="lidl-wrapper">
        <section className="main">
          <div className="main__image main__image--left">
            <StaticImage src={dropSupport} alt={supportImgAlt} />
          </div>
          <div className="main__image main__image--right">
            <StaticImage src={finish} alt={finishImgAlt} />
          </div>
        </section>
        <h1 className="promo-header">{contestTitle}</h1>
        <section className="contest">
          <div className="container">
            <p className="contest__description contest__description--small ">
              <span className="highlighted">{contestNumber}</span>
              <span className="highlighted highlighted--sign">{contestSign}</span>
              {contestBon}
            </p>
            <p className="contest__description">{contestPrice}</p>
          </div>
          <div className="container">
            <StaticImage src={finishBon} alt={finishBonAlt} />
          </div>
        </section>
        <section className="instruction">
          <div className="instruction__container instruction__container--left">
            <p>
              {firstStep}
              <span className="instruction__withLogo">{firstStepSpan}</span>
              <span className="instruction__highLighted">{firstStepReceipt}</span>
            </p>
            <div className="instruction__logo-finish">
              <StaticImage src={productsFinish} alt={finishProductsImgAlt} />
            </div>
          </div>
          <div className="instruction__container instruction__container--right">
            <p>
              {secondStep}
              <span className="instruction__highLighted">{secondStepSpan}</span>
            </p>
            <p className="custom">
              {thirdStep}
              <span>{thirdStepSpan}</span>
            </p>
          </div>
        </section>
        <section className="learn-more">
          <button type="button" onClick={toggleLdsModal} className="learn-more__button">
            {ldsButtonName}
          </button>
          <div className="learn-more__paragraphs-container">
            <p className="learn-more__paragraph">{competitionTime}</p>
            <p className="learn-more__paragraph">{isMobile ? detailsMobile : details}</p>
          </div>
        </section>
        <LdsModal
          ldsBody={data.lds.internal.content}
          isShown={isLdsModalShown}
          handleClose={toggleLdsModal}
        />
      </div>
    </>
  );
};

export default PromoPage;
