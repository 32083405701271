import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';

import { DangerouslySetInnerHtml } from 'layout';

import { LdsModalProps } from './models';

import './LdsModal.scss';

const LdsModal: FC<LdsModalProps> = ({ ldsBody, isShown, title, handleClose }) => (
  <Modal show={isShown} onHide={handleClose} size="xl" data-testid="lds-modal">
    <Modal.Header closeButton>{title ? <Modal.Title>{title}</Modal.Title> : null}</Modal.Header>
    <Modal.Body>
      <DangerouslySetInnerHtml html={ldsBody} />
    </Modal.Body>
    <Modal.Footer>
      <button type="button" onClick={handleClose}>
        Zamknij
      </button>
    </Modal.Footer>
  </Modal>
);

export default LdsModal;
